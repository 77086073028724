import React from 'react'
import links from 'links'
import { useAb } from 'hooks'
import { constants } from 'helpers'

import { messages as globalMessages } from 'modules/messages'
import { buttonMessages } from 'components/inputs'

import BannerBase, { type BannerBaseProps } from 'compositions/banners/BannerBase/BannerBase'

import bgImage from './images/bg.jpg'
import bgAbImage from './images/bg-ab.png'

import messages from './messages'


type TheEditBannerProps = {
  placement?: string
  'data-testid'?: string
  onClick?: () => void
}

const TheEditBanner: React.FunctionComponent<TheEditBannerProps> = (props) => {
  const { onClick, 'data-testid': dataTestId = 'theEditBanner', placement } = props

  const isTheValentinesEditEnabled = useAb(constants.abTests.theValentinesEdit) === 'b'
  const isTheLuxeEditEnabled = useAb(constants.abTests.theLuxeEdit) === 'b'

  let bannerBaseProps: Omit<BannerBaseProps, 'content'>

  if (isTheValentinesEditEnabled) {
    bannerBaseProps = {
      title: globalMessages.theValentinesEdit,
      text: messages.valentinesText,
      bgImage: bgAbImage,
      buttonTitle: buttonMessages.shopNow,
    }
  }
  else if (isTheLuxeEditEnabled) {
    bannerBaseProps = {
      title: globalMessages.theLuxeEdit,
      text: messages.luxeText,
      bgImage: bgAbImage,
      buttonTitle: buttonMessages.shopNow,
    }
  }
  else {
    bannerBaseProps = {
      title: messages.title,
      text: messages.text,
      bgImage: bgImage,
      buttonTitle: buttonMessages.learnMore,
    }
  }

  return (
    <BannerBase
      to={links.shop.theEdit}
      textColor="black"
      content="TheEdit"
      onClick={onClick}
      placement={placement}
      data-testid={dataTestId}
      {...bannerBaseProps}
    />
  )
}


export default TheEditBanner
