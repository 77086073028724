import React from 'react'
import links from 'links'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import bgImage from './images/bg.jpg'


const DriftSubscriptionNavBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <BannerBase
      to={links.driftSubscription}
      bgImage={bgImage}
      content="Drift subscription"
      sizes="560px"
      data-testid="driftSubscriptionNavBanner"
      onClick={onClick}
    />
  )
}


export default DriftSubscriptionNavBanner
