import React from 'react'
import { useAb, useFt } from 'hooks'
import { constants } from 'helpers'

import { useStrapiNavigationBanner } from 'modules/banners'

import { Bone } from 'components/layout'

import TheEditBanner from 'compositions/banners/TheEditBanner/TheEditBanner'
import {
  QuizBanner,
  CasesBanner,
  BrandsBanner,
  GiftSubscriptionBanner,
} from 'compositions/Header/view/Desktop/components/banners'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'


type DynamicNavigationBannerProps = {
  placement: 'perfumes' | 'colognes' | 'discover' | 'search' | 'gifts' | 'shop'
  onClick?: () => void
}

const DynamicNavigationBanner: React.FunctionComponent<DynamicNavigationBannerProps> = (props) => {
  const { placement, onClick } = props

  const isTheEditEnabled = useFt(constants.features.theEdit)
  const isStrapiBannersEnabled = useFt(constants.features.strapiBanners)
  const { isFetching, bannerData } = useStrapiNavigationBanner({ placement, onClick, skip: !isStrapiBannersEnabled })

  const isTheValentinesEditEnabled = useAb(constants.abTests.theValentinesEdit) === 'b'
  const isTheLuxeEditEnabled = useAb(constants.abTests.theLuxeEdit) === 'b'

  if (isFetching) {
    return (
      <Bone className="bg-light-beige" h={296} />
    )
  }

  if (placement === 'shop' && isTheEditEnabled && (isTheValentinesEditEnabled || isTheLuxeEditEnabled)) {
    return <TheEditBanner onClick={onClick} />
  }

  if (!isStrapiBannersEnabled || !bannerData) {
    if (placement === 'perfumes' || placement === 'colognes') {
      return (
        <BrandsBanner placement={placement} onClick={onClick} />
      )
    }

    if (placement === 'discover') {
      return (
        <QuizBanner onClick={onClick} />)
    }

    if (placement === 'gifts') {
      return (
        <GiftSubscriptionBanner onClick={onClick} />
      )
    }

    if (placement === 'shop') {
      const BannerComponent = isTheEditEnabled ? TheEditBanner : CasesBanner

      return (
        <BannerComponent onClick={onClick} />
      )
    }

    return null
  }

  if (!bannerData) {
    return null
  }

  const href = bannerData.isExternalLink ? bannerData.to : null

  return (
    <BannerBase
      to={bannerData.to}
      href={href}
      bgImage={bannerData.bgImage}
      content={null}
      sizes="560px"
      onClick={onClick}
      trackParams={bannerData.trackParams}
      data-testid="brandsBanner"
    />
  )
}


export default DynamicNavigationBanner
