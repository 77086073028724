import { useMemo } from 'react'
import links, { getLinkWithParams } from 'links'
import { useAb, useFt } from 'hooks'
import { constants } from 'helpers'
import { getAnalyticsContext, trackOnce } from 'analytics'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { messages as globalMessages } from 'modules/messages'
import { useReferralInvites, useUser } from 'modules/user'
import { usePrivateSale } from 'modules/products'
import { useStrapiWSTONavigationData } from 'modules/brands'
import { useCandleSubscription, useCaseSubscription, useDriftSubscription, useSubscription } from 'modules/subscription'
import { ExclusiveLabel } from 'components/dataDisplay'

import { navMessages as messages } from 'compositions/navigation'
import ReferralNavItem from 'compositions/Header/components/ReferralNavItem/ReferralNavItem'

import {
  BuyQueueBanner,
  CandleSubscriptionBanner,
  CaseSubscriptionBanner,
  DynamicNavigationBanner,
  StrapiWSTONavigationBanner,
  DriftSubscriptionNavBanner,
} from '../components/banners'


type GetGiftHubNavigationItemDataParams = {
  isHolidayMothersDayEnabled: boolean
  isHolidayFathersDayEnabled: boolean
  isGiftHubEvergreenEnabled: boolean
}

const getGiftHubNavigationItemData = (params: GetGiftHubNavigationItemDataParams) => {
  const { isHolidayMothersDayEnabled, isHolidayFathersDayEnabled, isGiftHubEvergreenEnabled } = params

  if (isHolidayMothersDayEnabled) {
    return {
      background: 'dark-pink' as const,
      exclusiveLabelVariant: 'dark-pink' as const,
      title: messages.gifts,
    }
  }

  if (isHolidayFathersDayEnabled) {
    return {
      background: 'gold-50' as const,
      exclusiveLabelVariant: 'gold-50' as const,
      title: messages.holidayGiftsFathersDay,
    }
  }

  return {
    background: 'vinous' as const,
    exclusiveLabelVariant: 'gold' as const,
    title: isGiftHubEvergreenEnabled ? messages.giftsEvergreen : messages.gifts,
  }
}

const useDesktopNav = () => {
  const isTheEditEnabled = useFt(constants.features.theEdit)
  const isTheShopEnabled = useFt(constants.features.theShop)
  const isCandleSubscriptionEnabled = useFt(constants.features.candleSubscription)
  const isFragranceFamiliesPageEnabled = useFt(constants.features.fragranceFamiliesPage)
  const isNotesLibraryPageEnabled = useFt(constants.features.noteLibPages)
  const isSaksCollaborationEnabled = useFt(constants.features.collaborationSaks)
  const isGiftHubEnabled = useFt(constants.features.giftHub)
  const isHolidayMothersDayEnabled = useFt(constants.features.holidayMothersDay)
  const isHolidayFathersDayEnabled = useFt(constants.features.holidayFathersDay)
  const isScentHoroscopeEnabled = useFt(constants.features.scentHoroscope)
  const isShopSamplesEnabled = useFt(constants.features.shopSamples)
  const isScentbirdPodcastEntryPointsEnabled = useFt(constants.features.scentbirdPodcastEntryPoints)
  const isTravelSizeEnabled = useFt(constants.features.travelSizeFragrances)
  const isDriftEcommercePromoEnabled = useFt(constants.features.driftEcommercePromo)
  const isMemorialSaleLabelEnabled = useFt(constants.features.memorialSale)
  const isCOARShopPageEnabled = useFt(constants.features.coarShopPage)
  const isDriftSubscriptionEnabled = useFt(constants.features.driftSubscription)
  const isGiftsUnder50PageEnabled = useFt(constants.features.giftsUnder50Page)
  const isBuy5pay4Enabled = useFeatureIsOn(constants.features.buy5pay4)
  const isPalmRoyaleCollaborationEnabled = useFeatureIsOn(constants.features.palmRoyaleCollaboration)
  const isRandomHouseCollaborationEnabled = useFeatureIsOn(constants.features.randomHouseCollaboration)
  const isBacheloretteCollaborationEnabled = useFeatureIsOn(constants.features.bacheloretteCollaboration)
  const isWeddingCollaborationEnabled = useFeatureIsOn(constants.features.weddingCollaboration)
  const isGiftHubEvergreenEnabled = useFeatureIsOn(constants.features.giftHubEvergreen)
  const isTheSummerEditEnabled = useFeatureIsOn(constants.features.theSummerEdit)

  const { gender, hasScentProfile } = useUser()

  const strapiWSTONavigationData = useStrapiWSTONavigationData()
  const isStrapiWSTONavigationDataEnabled = Boolean(strapiWSTONavigationData)
  const isStrapiWSTONavigationPerfumesBannerExists = Boolean(strapiWSTONavigationData?.banners?.perfumes)
  const isStrapiWSTONavigationColognesBannerExists = Boolean(strapiWSTONavigationData?.banners?.colognes)
  const isStrapiWSTONavigationDiscoverBannerExists = Boolean(strapiWSTONavigationData?.banners?.discover)

  const { privateSale } = usePrivateSale()
  const isPrivateSaleEnabled = Boolean(privateSale)

  const { subscription } = useSubscription()
  const isSubscribed = subscription?.isSubscribed
  const isBuyQueueEnabled = subscription?.stateOptions?.canBuyQueue
  const canApplyTossInOffer = subscription?.canApplyTossInOffer

  const { caseSubscription } = useCaseSubscription()
  const isCaseSubscriptionEnabled = caseSubscription?.isEnabled
  const isCaseSubscriptionSelected = caseSubscription?.isSelected

  const { candleSubscription } = useCandleSubscription()
  const isCandleSubscriptionSelected = candleSubscription?.isSelected
  const isCandleSubscriptionVisible = Boolean(isCandleSubscriptionEnabled && candleSubscription?.isEnabled)

  const { driftSubscription } = useDriftSubscription()
  const isDriftSubscriptionSelected = driftSubscription?.isSelected
  const isDriftSubscriptionVisible = Boolean(isDriftSubscriptionEnabled && driftSubscription?.isEnabled)

  const { stats: { available } } = useReferralInvites()
  const isReferralItemVisible = Boolean(available)

  const isTopLevelCandleClubEntryPointVisible = isCandleSubscriptionVisible && !isCandleSubscriptionSelected

  let discoverSectionBanner = isStrapiWSTONavigationDiscoverBannerExists ? StrapiWSTONavigationBanner : DynamicNavigationBanner
  const perfumesSectionBanner = isStrapiWSTONavigationPerfumesBannerExists ? StrapiWSTONavigationBanner : DynamicNavigationBanner
  const colognesSectionBanner = isStrapiWSTONavigationColognesBannerExists ? StrapiWSTONavigationBanner : DynamicNavigationBanner

  const isUpgradeEntryPointsEnabled = useAb(constants.abTests.upgradeEntryPoints) === 'b' && subscription?.isUpgradableFromMonthlyPlan
  const isScentHoroscopeInPerfumesEnabled = useAb(constants.abTests.navScentHoroscopeInPerfumes) === 'b'
  const isRecommendedForYouLinkEnabled = useAb(constants.abTests.recommendedForYouNavLink) === 'b'

  const isTheValentinesEditEnabled = useAb(constants.abTests.theValentinesEdit) === 'b'
  const isTheLuxeEditEnabled = useAb(constants.abTests.theLuxeEdit) === 'b'

  return useMemo(() => {
    let theEditTitle = messages.edit
    const theEditLabel = isHolidayMothersDayEnabled || isHolidayFathersDayEnabled
      ? messages.labels.new
      : isMemorialSaleLabelEnabled ? messages.labels.sale : undefined

    if (isHolidayFathersDayEnabled) {
      theEditTitle = globalMessages.theFathersDayEdit
    }
    else if (isHolidayMothersDayEnabled) {
      theEditTitle = globalMessages.theMothersDayEdit
    }
    else if (isTheValentinesEditEnabled) {
      theEditTitle = globalMessages.theValentinesEdit
    }
    else if (isTheLuxeEditEnabled) {
      theEditTitle = globalMessages.theLuxeEdit
    }
    else if (isTheSummerEditEnabled) {
      theEditTitle = messages.theSummerEdit
    }

    const giftHubNavigationItemData = isGiftHubEnabled
      ? getGiftHubNavigationItemData({
        isHolidayMothersDayEnabled,
        isHolidayFathersDayEnabled,
        isGiftHubEvergreenEnabled,
      })
      : null

    const collaborationItem = isSaksCollaborationEnabled && ({
      id: 'header-discover-scentbird-select',
      title: messages.scentbirdSelect,
      to: links.scentbirdSelect,
      label: messages.labels.new,
    })

    const recommendedForYouItem = {
      id: 'recommended-for-you',
      title: messages.recommendedForYou,
      to: links.scentProfile,
    }

    const data: Navigation.HeaderDesktopSection[] = [
      {
        id: 'perfumes',
        title: messages.perfumes,
        list: [
          {
            id: 'perfumes-all',
            title: messages.allPerfumes,
            to: links.subscription.perfumes,
          },
          isRecommendedForYouLinkEnabled && gender === 'MALE' && recommendedForYouItem,
          collaborationItem,
          isScentHoroscopeInPerfumesEnabled && isScentHoroscopeEnabled && {
            id: 'header-discover-scentHoroscope',
            title: messages.scentHoroscope,
            to: links.scentHoroscope,
            label: messages.labels.new,
          },
          {
            id: 'perfumes-new-arrivals',
            title: messages.newArrivals,
            to: links.newArrivals.perfumes,
          },
          {
            id: 'perfumes-bestsellers',
            title: messages.bestsellers,
            to: links.bestsellers.perfumes,
          },
          {
            id: 'perfumes-pom',
            title: messages.pom,
            to: links.subscription.currentPerfumeOfTheMonth,
          },
          {
            id: 'perfumes-clean',
            title: messages.clean,
            to: links.clean.perfumes,
          },
          {
            id: 'perfumes-exclusive',
            title: messages.exclusive,
            to: `${links.subscription.perfumes}?category=exclusive`,
          },
          {
            id: 'perfumes-collections',
            title: messages.collections,
            to: links.collectionsWomen,
          },
          {
            id: 'header-discover-allBrands',
            title: messages.allBrands,
            to: links.brands,
          },
        ].filter(Boolean),
        withColumns: true,
        bannerComponent: perfumesSectionBanner,
      },
      {
        id: 'colognes',
        title: messages.colognes,
        list: [
          {
            id: 'colognes-all',
            title: messages.allColognes,
            to: links.subscription.colognes,
          },
          isRecommendedForYouLinkEnabled && gender === 'FEMALE' && recommendedForYouItem,
          collaborationItem,
          isScentHoroscopeInPerfumesEnabled && isScentHoroscopeEnabled && {
            id: 'header-discover-scentHoroscope',
            title: messages.scentHoroscope,
            to: links.scentHoroscope,
            label: messages.labels.new,
          },
          {
            id: 'colognes-new-arrivals',
            title: messages.newArrivals,
            to: links.newArrivals.colognes,
          },
          {
            id: 'colognes-bestsellers',
            title: messages.bestsellers,
            to: links.bestsellers.colognes,
          },
          {
            id: 'colognes-com',
            title: messages.com,
            to: links.subscription.currentCologneOfTheMonth,
          },
          {
            id: 'colognes-clean',
            title: messages.clean,
            to: links.clean.colognes,
          },
          {
            id: 'colognes-exclusive',
            title: messages.exclusive,
            to: `${links.subscription.colognes}?category=exclusive`,
          },
          {
            id: 'colognes-collections',
            title: messages.collections,
            to: links.collectionsMen,
          },
          {
            id: 'header-discover-allBrands',
            title: messages.allBrands,
            to: links.brands,
          },
        ].filter(Boolean),
        withColumns: true,
        bannerComponent: colognesSectionBanner,
      },
      {
        id: 'beauty',
        title: messages.beauty,
        to: links.subscription.beauty,
      },
      isUpgradeEntryPointsEnabled && {
        id: 'header-upgrade',
        title: messages.upgrade2Scents,
        to: links.upgradeProduct,
      },
      !isUpgradeEntryPointsEnabled && isTopLevelCandleClubEntryPointVisible && {
        id: 'candleClub',
        title: messages.candleSubscription,
        to: links.candleSubscription,
      },
      { isDivider: true },
      {
        id: 'shop',
        title: messages.shop,
        list: [
          isGiftHubEnabled && {
            id: 'header-gift-hub',
            title: giftHubNavigationItemData.title,
            to: links.gifts,
          },
          isGiftsUnder50PageEnabled && {
            id: 'header-giftsUnder50',
            title: messages.giftsUnder50,
            to: links.giftsUnder50,
          },
          isDriftEcommercePromoEnabled && {
            id: 'header-drift-ecommerce-promo',
            title: messages.driftEcommercePromo,
            to: `${getLinkWithParams(links.brand, { name: 'drift' })}?productInputSource=THE_EDIT&sourceProductGroup=GiftSet`,
            label: messages.labels.new,
          },
          isTravelSizeEnabled && {
            id: 'header-shop-travelSizeFragrances',
            title: messages.travelSizeFragrances,
            to: gender === 'FEMALE' ? links.shop.travelSize.perfumes : links.shop.travelSize.colognes,
            label: isBuy5pay4Enabled ? messages.labels.sale : undefined,
          },
          isShopSamplesEnabled && {
            id: 'header-shop-samples',
            title: messages.samples,
            to: links.shop.samples.root,
          },
          isTheShopEnabled && {
            id: 'header-shop-fullSizeFragrances',
            title: messages.fullSizeFragrances,
            to: gender === 'FEMALE' ? links.shop.perfumes : links.shop.colognes,
            label: isMemorialSaleLabelEnabled && messages.labels.sale,
          },
          isCOARShopPageEnabled && {
            id: 'header-shop-coar',
            title: messages.coarShopPage,
            to: links.shop.coar,
            label: messages.labels.new,
          },
          isTheEditEnabled && {
            id: 'header-shop-edit',
            title: theEditTitle,
            to: links.shop.theEdit,
            label: theEditLabel,
          },
          isTheShopEnabled && {
            id: 'header-shop-candlesHomeScents',
            title: messages.candlesHomeScents,
            to: links.shop.candlesHomeScents,
            label: isMemorialSaleLabelEnabled && messages.labels.sale,
          },
          {
            id: 'header-shop-scentbirdCases',
            title: messages.scentbirdCases,
            to: links.shop.scentbirdCases,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        withColumns: true,
        bannerComponent: DynamicNavigationBanner,
      },
      {
        id: 'discover',
        title: messages.discover,
        list: [
          {
            id: 'header-discover-scentProfile',
            title: messages.scentProfile,
            to: hasScentProfile ? links.scentProfile : links.smartRecommendations,
          },
          isStrapiWSTONavigationDataEnabled && {
            id: 'header-discover-featuredBrand',
            title: messages.featuredBrand,
            to: strapiWSTONavigationData.navLink,
          },
          !isScentHoroscopeInPerfumesEnabled && isScentHoroscopeEnabled && {
            id: 'header-discover-scentHoroscope',
            title: messages.scentHoroscope,
            to: links.scentHoroscope,
            label: messages.labels.new,
          },
          {
            id: 'header-discover-fomArchive',
            title: messages.fomArchive,
            to: links.subscription.perfumeOfTheMonthList,
          },
          isNotesLibraryPageEnabled && {
            id: 'header-discover-notesLibrary',
            title: messages.notesLibrary,
            to: links.notes,
          },
          isFragranceFamiliesPageEnabled && {
            id: 'header-discover-fragranceFamilies',
            title: messages.fragranceFamilies,
            to: links.fragranceFamilies,
          },
          {
            id: 'header-discover-scentBlog',
            title: messages.scentBlog,
            toTab: links.blog,
          },
          isScentbirdPodcastEntryPointsEnabled && {
            id: 'header-discover-podcast',
            title: messages.scentWorldPodcast,
            href: constants.externalLinks.scentbirdPodcast,
            label: messages.labels.new,
          },
          isPalmRoyaleCollaborationEnabled && {
            id: 'header-discover-palm-royale',
            title: messages.palmRoyaleCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'palmroyale' }),
            label: messages.labels.new,
          },
          isRandomHouseCollaborationEnabled && {
            id: 'header-discover-random-house',
            title: messages.randomHouseCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'randomhouse' }),
            label: messages.labels.new,
          },
          isBacheloretteCollaborationEnabled && {
            id: 'header-discover-bachelorette',
            title: messages.bacheloretteCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'abc-bachelorette' }),
            label: messages.labels.new,
          },
          isWeddingCollaborationEnabled && {
            id: 'header-discover-wedding',
            title: messages.weddingCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'wedding' }),
            label: messages.labels.new,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        withColumns: true,
        bannerComponent: discoverSectionBanner,
        onDisplay: () => {
          const context = getAnalyticsContext()

          if (context.subscriptionStatus !== 'LEAD') {
            trackOnce('Discover menu display')
          }
        },
      },
      isSubscribed && {
        id: 'member perks',
        title: messages.memberPerks,
        list: [
          isDriftSubscriptionVisible && {
            id: 'header-member-perks-driftSubscription',
            title: messages.driftSubscription,
            to: links.driftSubscription,
            label: isDriftSubscriptionSelected ? messages.labels.subscribed : messages.labels.new,
          },
          isBuyQueueEnabled && {
            id: 'header-member-perks-buyYourQueue',
            title: messages.buyYourQueue,
            to: links.buyQueue,
          },
          isPrivateSaleEnabled && {
            id: 'header-member-perks-privateSale',
            title: messages.privateSale,
            to: links.privateSale,
          },
          isReferralItemVisible && {
            id: 'referral-nav-item',
            to: links.invite,
            title: messages.referralProgram,
            content: (
              <ReferralNavItem
                spanClassName="text-white bg-gold-70 text-p4"
                freeFragranceClassName="text-gold-70"
              />
            ),
          },
          canApplyTossInOffer && {
            id: 'header-member-perks-lastMinuteAddons',
            title: messages.lastMinuteAddons,
            to: links.tossIn,
          },
          isCaseSubscriptionEnabled && {
            id: 'header-member-perks-caseSubscription',
            title: messages.caseSubscription,
            to: links.caseSubscription,
          },
          isCandleSubscriptionVisible && {
            id: 'header-member-perks-candleSubscription',
            title: messages.candleSubscription,
            to: links.candleSubscription,
            label: isCandleSubscriptionSelected ? messages.labels.subscribed : messages.labels.new,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        withColumns: false,
        bannerComponent: ({ onClick }) => {
          if (isDriftSubscriptionVisible && !isDriftSubscriptionSelected) {
            return (
              <DriftSubscriptionNavBanner onClick={onClick} />
            )
          }

          if (isCandleSubscriptionVisible && !isCandleSubscriptionSelected) {
            return (
              <CandleSubscriptionBanner onClick={onClick} />
            )
          }

          if (isCaseSubscriptionEnabled && !isCaseSubscriptionSelected) {
            return (
              <CaseSubscriptionBanner onClick={onClick} />
            )
          }

          return <BuyQueueBanner onClick={onClick} />
        },
      },
      {
        id: 'gifts',
        title: isGiftHubEvergreenEnabled ? messages.giftsMenuEvergreen : messages.gifts,
        list: [
          isGiftHubEnabled && {
            id: 'header-gift-hub',
            title: giftHubNavigationItemData.title,
            to: links.gifts,
            exclusiveLabel: (
              <ExclusiveLabel
                labelText={messages.exclusives}
                variant={giftHubNavigationItemData.exclusiveLabelVariant}
              />
            ),
          },
          {
            id: 'header-gifts-subscription',
            title: messages.giftSubscription,
            to: links.giftSubscription,
          },
          isGiftsUnder50PageEnabled && {
            id: 'header-gifts-giftsUnder50',
            title: messages.giftsUnder50,
            to: links.giftsUnder50,
          },
          {
            id: 'header-gifts-card',
            title: messages.giftCard,
            to: links.giftCard,
          },
          {
            id: 'header-gift-sets',
            title: messages.giftSets,
            to: links.giftSets.root,
          },
          !isGiftHubEnabled && {
            id: 'header-gifts',
            title: messages.allGifts,
            to: links.gifts,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        background: giftHubNavigationItemData?.background,
        withColumns: false,
        bannerComponent: DynamicNavigationBanner,
      },
    ]

    return data.filter(Boolean)
  }, [
    gender,
    hasScentProfile,
    discoverSectionBanner,
    isGiftHubEnabled,
    isStrapiWSTONavigationDataEnabled,
    strapiWSTONavigationData?.navLink,
    isTheEditEnabled,
    isBuyQueueEnabled,
    canApplyTossInOffer,
    isCandleSubscriptionVisible,
    isCandleSubscriptionSelected,
    isCaseSubscriptionEnabled,
    isCaseSubscriptionSelected,
    isFragranceFamiliesPageEnabled,
    isNotesLibraryPageEnabled,
    isSubscribed,
    isTheShopEnabled,
    isPrivateSaleEnabled,
    isTopLevelCandleClubEntryPointVisible,
    isSaksCollaborationEnabled,
    isScentHoroscopeEnabled,
    isHolidayMothersDayEnabled,
    isHolidayFathersDayEnabled,
    isShopSamplesEnabled,
    isScentbirdPodcastEntryPointsEnabled,
    isReferralItemVisible,
    perfumesSectionBanner,
    colognesSectionBanner,
    isUpgradeEntryPointsEnabled,
    isTravelSizeEnabled,
    isDriftEcommercePromoEnabled,
    isMemorialSaleLabelEnabled,
    isCOARShopPageEnabled,
    isScentHoroscopeInPerfumesEnabled,
    isDriftSubscriptionVisible,
    isDriftSubscriptionSelected,
    isGiftsUnder50PageEnabled,
    isBuy5pay4Enabled,
    isPalmRoyaleCollaborationEnabled,
    isRandomHouseCollaborationEnabled,
    isBacheloretteCollaborationEnabled,
    isRecommendedForYouLinkEnabled,
    isGiftHubEvergreenEnabled,
    isWeddingCollaborationEnabled,
    isTheSummerEditEnabled,
    isTheLuxeEditEnabled,
    isTheValentinesEditEnabled,
  ])
}


export default useDesktopNav
